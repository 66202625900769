// fetch.js
const ACC_KEY = "9eb2cf9bd399ef03f29d3334c2386a66";
const API_URL = "https://www.powerlego.com/ApiGateway/";
const GOOGLE_MAP_RUL = " https://maps.googleapis.com/maps/api/geocode/";
export const GOOGLE_API_KEY = "AIzaSyCsreMGj8xStaCAs9V2HjMizheuJaVlII0";

//address
export const addressValidator = async (val: any) => {
    const string = val.toString().replace(/#/, '')

    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${ACC_KEY}`);

    const formdata = new FormData();
    formdata.append("term", string);

    const response = await fetch(`${API_URL}v1/address_validator`, {
        method: 'POST',
        headers: myHeaders,
        body: formdata
    })
    return response.json()
}

//utility
export const getUtility = async (val: number) => {
    if (val !== undefined) {
        const string = val.toString().replace(/#/, '');

        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${ACC_KEY}`);
        const formdata = new FormData();
        formdata.append("zipcode", string);

        const response = await fetch(`${API_URL}v1/get_utility`, {
            method: 'POST',
            headers: myHeaders,
            body: formdata
        })
        return response.json()
    }

}

//google map 
export const geocode = async (val: any) => {
    if (val !== undefined) {
        let arr = val.split(" ");
        for (var i = 0; i < arr.length; i++) {
            arr[i] = "+" + arr[i];
        }
        let address = arr.join("");
        const response = await fetch(`${GOOGLE_MAP_RUL}json?address=${address}&key=${GOOGLE_API_KEY} `, {
            method: 'POST',
        })
        return response.json()
    }
}

//detail
export const premiseDetail = async (zipcode: any, address: any) => {
    if (zipcode !== undefined && address !== undefined) {
        const string = address.toString().replace(/#/, '')

        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${ACC_KEY}`);

        const formdata = new FormData();
        formdata.append("address", string);
        formdata.append("zipcode", zipcode);

        const response = await fetch(`${API_URL}v2/premise/detail`, {
            method: 'POST',
            headers: myHeaders,
            body: formdata
        })
        return response.json()
    }
}


export const usageEstimator = async (val: any) => {
    if ( val != null) {
        let { street, city, state, zipcode }: any = val;
        const _street = street.toString().replace(/#/, '');
        const _city = city.toString().replace(/#/, '');
        const _state = state.toString().replace(/#/, '');
        const _zipcode = zipcode.toString().replace(/#/, '');

        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${ACC_KEY}`);
        const formdata = new FormData();
        formdata.append("address1", _street);
        formdata.append("city", _city);
        formdata.append("state", _state);
        formdata.append("zipcode", _zipcode);

        const response = await fetch(`${API_URL}v1/usage_estimator`, {
            method: 'POST',
            headers: myHeaders,
            body: formdata
        })
        return response.json()
    }

}

//get
export const get_usage = async (usages: any, zipcode: any, utility_code: any) => {
    if (usages !== undefined && zipcode !== undefined && utility_code !== undefined) {
        const _zipcode = zipcode.toString().replace(/#/, '');
        const _utility_code = utility_code.toString().replace(/#/, '');

        let _usages: Array<any> = Object.values(usages);

        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${ACC_KEY}`);

        const formdata = new FormData();
        formdata.append("zipcode", _zipcode);
        formdata.append("utility_code", _utility_code);
        formdata.append("filter[type]", "normal");
        _usages.forEach((val: any, index: number) => {
            formdata.append(`usage[monthly][${index + 1}]`, val);
        })


        const response = await fetch(`${API_URL}v2/plan/get`, {
            method: 'POST',
            headers: myHeaders,
            body: formdata
        })
        return response.json()
    }

}


//fetch email
export const contactUs = async (val:any) => {
    let {site , name , email , company , _message} = val;
    const _site = site.toString().replace(/#/, '');
    const _name = name.toString().replace(/#/, '')
    const _email = email.toString().replace(/#/, '')
    const _company = company.toString().replace(/#/, '')
    const message = _message.toString().replace(/#/, '')

    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${ACC_KEY}`);

    const formdata = new FormData();
    formdata.append("site", _site);
    formdata.append("name", _name);
    formdata.append("email", _email);
    formdata.append("company", _company);
    formdata.append("message", message);

    const response = await fetch(`https://www.powerlego.com/contact/website`, {
        method: 'POST',
        headers: myHeaders,
        body: formdata
    })
    return response.json()
}

const functionA = () => {
    addressValidator('xxxxxxx').then(data => {
        // TODO
    })
}