import { Button, Card, message, Table } from "antd";
import copy from "copy-to-clipboard";
import { useEffect, useState } from "react";
import { Bar, BarChart, CartesianGrid, ResponsiveContainer, XAxis, YAxis, Tooltip } from "recharts";
import data from "../component/data";
import { GoogleMap } from "../component/GoogleMap";
import { ApiOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";


const chartData = (props: any) => {
    // let { usages } = props;
    const data = [{ month: "Jan", value: 0 },
    { month: "Feb", value: 0 },
    { month: "Mar", value: 0 },
    { month: "Apr", value: 0 },
    { month: "May", value: 0 },
    { month: "Jun", value: 0 },
    { month: "Jul", value: 0 },
    { month: "Aug", value: 0 },
    { month: "Sept", value: 0 },
    { month: "Oct", value: 0 },
    { month: "Nov", value: 0 },
    { month: "Dec", value: 0 }];

    let _usages = Object.values(props);
    let _data: any[] = [];
    data.forEach((o: any, index: number) => {
        o.value = _usages[index];
        _data.push(o);

    });
    return _data;
}

const chartPlans = (props: any) => {
    const data: any = [];
    let usages = props;
    usages.forEach((e: any) => {
        var a = { name: "", price: "" }
        a.name = e.source.toUpperCase();
        a.price = e.rate;
        data.push(a);
    })

    return data;

}

const addressAndEsiid = (address: any) => {
    const data: any = [];
    address.map((o: any) => {
        let { label, esiid } = o;
        data.push({ value: `${label} - ${esiid}` })
    })
    return data;
}

const toThousands = (num: any) => {
    var result = [], counter = 0;
    num = (num || 0).toString().split('');
    for (var i = num.length - 1; i >= 0; i--) {
        counter++;
        result.unshift(num[i]);
        if (!(counter % 3) && i != 0) { result.unshift(','); }
    }
    return result.join('');
}

const isEmail = (str: string) => {
    var reg = /^([a-zA-Z]|[0-9])(\w|\-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/;
    return reg.test(str);
}

const getEsiidNum = () => {

    let xmlhttp: any;
    xmlhttp = new XMLHttpRequest();
    xmlhttp.open("GET", "https://www.powerlego.com/esiids.txt", false);
    xmlhttp.send();
    var responseData = xmlhttp.response;
    var responseBody = xmlhttp.responseText;
    return responseBody;
}

const EsiidCard = (props: {
    content: any
}) => {
    const [copyStatus, setCopyStatus] = useState(false);
    let { content } = props;
    let { esiid, status }: any = content;
    //copy
    const copyId = () => {
        copy(esiid);
        setCopyStatus(true);
        message.success('Copy success');
    }
    return <Card title="ESID Number(S)" bordered={false} className="esid">
        <div className='id'>
            <label>{content.esiid}</label>
            {(status === "Active" && copyStatus) && <span className="status">{status}</span>}
            {(status === "De-Energized" && copyStatus) && <span className="de-energized"><ApiOutlined />{status}</span>}
            <Button className='copy_id' onClick={() => copyId()}>COPY</Button>
        </div>
    </Card>
}

const EsiidApiCard = () => {

    const navigate = useNavigate();

    return <Card title="Esiid API" bordered={false} className="api">
        <label>{data.esiid_API}</label>
        <Button onClick={() => { navigate('/contact') }}>CONTACT US</Button>
    </Card>
}

const GoogleMapCard = (props: {
    status: any,
    premise_type: any,
    utility_name: any,
    address: any
}) => {
    let { status, premise_type, utility_name, address } = props;
    return <Card title="Address Info" bordered={false} className="address" style={{ position: 'relative' }}>
        {premise_type !== "Residential" && <div style={{
            position: 'absolute',
            top: '0',
            right: '0',
            backgroundImage: 'linear-gradient(to right,#fff,#434343)',
            width: 200,
            borderRadius: "0 19px 0 0",
            textAlign: 'end',
            padding: '3px 20px',
            color: '#fff',
            fontSize: 12,
        }}>{premise_type === 'Small Non-Residential' ? 'Small Business' : 'Commercial'}</div>}
        <GoogleMap address={address} />
        <ul>
            <li className='label'>
                <label>Status</label>
                <label>Premise Type</label>
                <label>Utility</label>
            </li>
            <li className='span'>
                <span>{status}</span>
                <span>{premise_type}</span>
                <span>{utility_name}</span>
            </li>
        </ul>
    </Card>
}

const HomeAreaCard = (props: { houseArea: any }) => {
    let { houseArea } = props;
    let { bathrooms, bedrooms, lot_size }: any = houseArea || {};
    return <Card title="House Area" bordered={false} className="house">
        <span className='size'>{lot_size === "" ? "0" : toThousands(lot_size)} </span>
        <div className='rooms'>
            <span className='bedroom'>{bedrooms === "" ? "0" : bedrooms}</span>
            <span className='bathroom'>{bathrooms === "" ? "0" : bathrooms}</span>
        </div>

    </Card>
}

const EstimatedUsageCard = (props: { chartData: any, premise_type?: any }) => {
    let { chartData, premise_type } = props;
    let [showData, setShowData] = useState(chartData)

    useEffect(() => {
        if (premise_type !== "Residential") {
            let newData = chartData?.map((item: any) => ({
                ...item,
                value: item.value < 3000 && item.value * 5
            }))
            setShowData(newData)
        } else {
            setShowData(chartData)
        }
    }, [chartData])

    return <Card title="Estimated Usage" bordered={false} className="estimated">
        <ResponsiveContainer height={200}>
            <BarChart data={showData} margin={{ top: 5, right: 30, left: 0, bottom: 5 }}>
                <CartesianGrid strokeDasharray="3 3" vertical={false} />
                <XAxis dataKey="month" interval={1} />
                <YAxis />
                <Tooltip itemStyle={{ color: "black" }} separator=" " />
                <Bar dataKey="value" fill='#e8ecec' name=" " unit=" kwh" />
            </BarChart>
        </ResponsiveContainer>
        <p>{data.usage_intro}</p>
    </Card>

}

const PricingCard = (props: { plan: any}) => {

    const [date, setDate] = useState<any>();

    const getDate = () => {
        var d = new Date();
        var a = d.toDateString();
        setDate(a.slice(3, a.length + 1));
    }

    useEffect(() => {
        getDate();
    }, [])

    let { plan } = props;

    return <Card title={`Pricing-${date}`} bordered={false} className="data">
        <span className='chart_title'>Lowest Price For Address (TOP5)</span>
        <Table
            columns={[{
                title: 'Plan Name',
                dataIndex: 'plan_name',
                align: 'center',
                key: 'plan_name'
            }, {
                title: 'Contract',
                dataIndex: 'contract_term',
                align: 'center',
                key: 'contract_term'
            }, {
                title: 'Rate',
                dataIndex: 'rate',
                align: 'center',
                key: 'rate',
                render: (rate) => {
                    return <span>{rate}￠</span>
                }
            }]}
            size="small"
            dataSource={plan}
            pagination={false}
            rowKey='plan_id'
        >
        </Table>
    </Card>
}

export {
    chartData, chartPlans, addressAndEsiid, EsiidCard, EsiidApiCard, GoogleMapCard,
    HomeAreaCard, EstimatedUsageCard, PricingCard, getEsiidNum, toThousands, isEmail
};