import { useEffect } from 'react';
import "../styles/footerLink.scss";
import { TOSContent } from './TOS';
import { PrivacyPolicyContent } from './PrivacyPolicy';
export const TermsOfUse = () => {

    useEffect(() => {
        toTop();
    }, [])

    const toTop = () => {
        window.scroll({
            top: 0,
            behavior: 'smooth'
        });

    };



    return <div className="footer-pages">
        <div className="title">
            <label >Terms Of Use</label>
        </div>
        <div className='intro'><TOSContent /></div>
    </div>

}

export const FAQ = () => {
    
    useEffect(() => {
        toTop();
    }, [])

    const toTop = () => {
        window.scroll({
            top: 0,
            behavior: 'smooth'
        });

    };



    return <div className="footer-pages">
        <div className="title">
            <label>FAQ</label>
        </div>
        <div className='intro'>Some content.</div>
    </div>

}


export const PrivacyPolicy = () => {
    
    useEffect(() => {
        toTop();
    }, [])

    const toTop = () => {
        window.scroll({
            top: 0,
            behavior: 'smooth'
        });

    };



    return <div className="footer-pages">
        <div className="title">
            <label>Privacy Policy</label>
        </div>
        <div className='intro'><PrivacyPolicyContent /></div>
    </div>

}


