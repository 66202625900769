import { AutoComplete, Button, Layout } from 'antd';
import 'antd/dist/antd.css';
import { Footer } from 'antd/lib/layout/layout';
import throttle from 'lodash/debounce';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { EsiidApiCard, EsiidCard, EstimatedUsageCard, GoogleMapCard, HomeAreaCard, PricingCard, addressAndEsiid, chartData } from '../../common/Comps';
import data from '../../component/data';
import "../../styles/home.scss";
import { addressValidator, getUtility, get_usage, premiseDetail, usageEstimator } from '../services/fetch_example';
import { ContactModal } from '../../component/contact_modal';

const { Content } = Layout;

const Home = () => {
    const [address, setAddress] = useState<Array<any>>([]);
    const [content, setContent] = useState<any>();
    const [utility, setUtility] = useState<any>();
    const [value, setValue] = useState<string>();
    const [houseArea, setHouseArea] = useState();
    const [usages, setUsages] = useState<Array<any>>();//api date
    const [_usages, set_usages] = useState<Array<any>>();
    const [plan, setPlan] = useState();
    const [options, setOptions] = useState([]);
    const [showModal, setShowModal] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        getPremiseDetail();
        getUsages();
        getutility();
        toHome();
    }, [content])

    //search address
    const searchAddress = throttle((val: any) => {
        // const result = await addressValidator(val);
        let str = val.trim();
        if (str != "") {
            addressValidator(str).then((result) => {
                setAddress(result);
            })
        } else {
            return null;
        }

    }, 500)

    //getDetail
    const getPremiseDetail = () => {
        let { zipcode, street }: any = content || {};
        premiseDetail(zipcode, street).then((result) => {
            if (result != null) {
                let { premise } = result;
                setHouseArea(premise.detail);
            }

        })
    }
    //address onChange
    const handleChangeCom = (label: any) => {
        if (label != null) {
            var o = label.split("-");
            var esiid = o[o.length - 1].trim();
            setValue(label);
            let _address = address.filter(item => item.esiid === esiid)[0] || {};
            setContent(_address);
            if (_address.premise_type === 'Small Non-Residential') {
                setShowModal(true);
            }
        }
    }

    const getutility = () => {
        if (content !== undefined) {
            let { zipcode } = content;
            getUtility(zipcode).then((result) => {
                setUtility(result[0]);
            })
        }

    }
    //goolge map get code
    // const getCode = () => {
    //     if (content !== undefined) {
    //         let { value } = content;
    //         geocode(value).then((result) => {
    //             if (result != null) {
    //                 let geometry = result.results[0].geometry || {};
    //                 setCode(geometry.location);//get lat lng
    //             }
    //         })
    //     }
    // }
    //get esiidNums 

    // useEffect(() => {
    //     getEsiidNums();
    // }, [])

    // const getEsiidNums = () => {
    //     var num = getEsiidNum();
    //     setEsiidNums(toThousands(num));
    // }
    useEffect(() => {
        getOption();
    }, [address]);

    const getOption = () => {
        setOptions(addressAndEsiid(address));
    }

    const toHome = () => {
        const contentWidth = document.documentElement.clientWidth || document.body.clientWidth;
        let top = 0;
        if (content) {
            setTimeout(function () {
                if (contentWidth < 1200) {
                    top = 485;
                } else {
                    top = 840;
                }
                window.scroll({
                    top: top,
                    behavior: 'smooth'
                });
            }, 1000);
        }
    };

    //get usage 
    const getUsages = () => {
        if (content !== null) {
            usageEstimator(content).then((result) => {
                if (result != null) {
                    let _data = chartData(result.usages);
                    setUsages(result.usages);
                    set_usages(_data);
                }
            })
        }
    }
    useEffect(() => {
        getPlans();
    }, [usages, utility])

    //get plans
    const getPlans = throttle(() => {
        let { zipcode, utility_code } = utility || {};
        get_usage(usages, zipcode, utility_code).then((result) => {
            if (result != null) {
                let { plans } = result.response || {};
                var list = plans.slice(0, 5);
                setPlan(list);
            }
        });
    }, 1000);

    let { status, premise_type }: any = content || {};
    let { utility_name } = utility || {};

    return <div className="home" key="home">
        <Layout>
            <Content>
                <div className="index">
                    <video className='video' preload='auto' loop muted playsInline autoPlay
                        tabIndex={-1} >
                        <source src={require("../video/GIF.mp4")} />
                    </video>
                    <div className='top'>
                        <label className='title'>{data.title}</label>
                        <label className='intro'>{data.intro}</label>
                    </div>
                    <div className='select'>
                        <AutoComplete
                            options={options}
                            onSelect={handleChangeCom}
                            onSearch={searchAddress}
                            allowClear={true}
                            placeholder="Please Enter Your Address..."
                        />
                    </div>
                </div>

                {content && utility && <div className='middle'>
                    {/* <div className='header'><img src={require("../image/icon/icon-home.png")} alt="" />Your Home</div> */}
                    <div className='cards'>
                        <div className='left'>
                            <EsiidCard content={content} />
                            <GoogleMapCard status={status} premise_type={premise_type} utility_name={utility_name} address={content.label} />
                            {premise_type === "Residential" && <EsiidApiCard />}
                        </div>
                        <div className='right'>
                            {premise_type === "Residential" && <HomeAreaCard houseArea={houseArea} />}
                            {premise_type === "Residential" && <EstimatedUsageCard chartData={_usages} premise_type={premise_type} />}
                            <PricingCard plan={plan}  />
                            {premise_type !== "Residential" && <EsiidApiCard />}
                        </div>
                    </div>
                </div>}
            </Content>
            <Footer>
                <div className='bottom-intro'>
                    <div className='left'>
                        <label className='foot_inquire'>{data.foot_inquire}</label>
                        <label className='foot_content'>{data.foot_content}</label>
                        <label className='foot_number'>{data.number}</label>
                        <label className='foot_intro'>{data.foot_intro}</label>
                    </div>
                    <img src={require('../image/pic-6.png')} alt="" />
                </div>
                <div className='document'>
                    <label className='developers'>Designed for <span>developers</span></label>
                    <label className='api-intro'>{data.api_intro}</label>
                    {/* <label className='documentation'> <span>Read documentation</span></label> */}
                </div>
                <div className='api-example'>
                    <img src={require('../image/pic-5.png')} alt="" />
                    <div className='example-intro'>
                        <label>{data.example_intro}</label>
                        <Button onClick={() => { navigate('/contact') }}>CONTACT US</Button>
                    </div>
                </div>

                <ul className='esiid_api_intro'>
                    <li className='inquire'>{data.esiid_api_inquire}</li>
                    <li className='intro1'>{data.esiid_api_intro1}</li>
                    <li className='intro2'>{data.esiid_api_intro2}</li>
                    <li className='intro3'>{data.esiid_api_intro3}</li>
                    <li className='intro4'>{data.esiid_api_intro4}</li>
                </ul>
            </Footer>
        </Layout>
        <ContactModal show={showModal} close={()=>{setShowModal(false)}}/>
    </div >
}
export default Home;
