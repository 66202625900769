import Modal from "antd/lib/modal/Modal"

export const ContactModal = (props: { show: boolean, close: any }) => {

    const { show, close } = props;

    return <Modal open={show} footer={null} onCancel={close}>
        <div style={{padding:'40px'}} className="contact-phone-modal">
           <p>The selected address is registered as a business address. To learn more about our electric service and sign up for a great deal, please call us at <a href="tel:888-301-3930">888-301-3930</a>. Thank you for considering our service.</p>
        </div>
    </Modal>
}