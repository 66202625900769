const data = {
    title: "The Best Way to Find a Texas ESIID",
    intro: "Our database has +12,266,567 unique ESIID",
    head_inquire: "What is an ESIID?",
    head_content: "In Texas,your ESIID stands for the physical address where you get electricity.t'sa unique 17 or 22-digit number assigned to the electricity meter for your house,apart- ment,or business,If your property has more than one meter,you'l have an ESIID for each.ESIIDs aren't listed on meters.To find yours,search horo or check on your elec- tricity bill.",
    foot_inquire: "What is ESIID.io doing?",
    foot_content: 'ESIID.io provides unparalleled ESIID – address matching capabilities with over 12 million Texas ESIIDs in its database.You can check any Texas address using the search field above to immediately find the ESIID connected to that address.',
    number: "12,266,567+",
    foot_intro: "Number of ESIIDs in our database updated daily.",
    esiid_API: "Provides exceptional address verification using fuzzy matching, type ahead address picker technology to quickly and accurately find a user's home address and ESIID.",
    api_intro: "ESIID API is a developer integration toolkit to let users find Esiid in any app,website or web plugin",
    usage_intro: "Rate is calculated by estimated usage by zip code and your SMT data.Adjust with your own estimates.",
    example_intro: "Provides exceptional address verification using fuzzy matching,type ahead address picker technology to quickly and accurately find a user's home address and ESIID.",
    esiid_api_inquire: "Why use Esiid API",
    esiid_api_intro1: "ESIIDlookup can match over 99% of all addresses in ERCOT where validation can be a major source of frustration for users and data error for our clients.",
    esiid_api_intro2: "Inaccurate of ineffective address validation is a very common problem for many REP websites that kills conversion and drives customers away.",
    esiid_api_intro3: "Eliminates the frustration many customers encounter by not being able to find their home address and not finding their ESIID.",
    esiid_api_intro4: "The importance of a smooth and accurate address validation process cannot be overstated from a customer experience,sales and operational efficiency perspective.",
}
export default data;

export const PHONENUMBER = '888-301-3930'