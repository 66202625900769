import { GOOGLE_API_KEY } from '../pages/services/fetch_example';

export const GoogleMap = ({ address }: {
   address:any
}) => {

    let arr = address.split(" ");
    
    for (var i = 0; i < arr.length; i++) {
        arr[i] = "+" + arr[i];
    }
    let _address = arr.join("");
    
    if (address) {
        return (
            <div className="googlemap-wrapper">
                <iframe
                    width="800"
                    height="450"
                    loading="lazy"
                    allowFullScreen
                    title='googleMap'
                    style={{border:0}}
                    referrerPolicy="no-referrer-when-downgrade"
                    src={`https://www.google.com/maps/embed/v1/place?key=${GOOGLE_API_KEY}&q=${_address}&zoom=17`}>
                </iframe>
            </div>
        )
    }
    return null;
}

const Marker = ({ text }: {
    text: string,
    lat: number,
    lng: number
}) => {
    return (
        <div className="map-marker">{text}</div>
    )
}
