import { ProForm, ProFormText, ProFormTextArea } from '@ant-design/pro-components';
import { Alert, message } from 'antd';
import { useEffect, useState } from 'react';
import { isEmail } from '../../common/Comps';
import "../../styles/contact.scss";
import { contactUs } from '../services/fetch_example';

export const Contact = () => {

    const [submitStatus, setSubmitStatus] = useState(false);

    useEffect(() => {
        toTop();
    }, [])

    const toTop = () => {
        window.scroll({
            top: 0,
            behavior: 'smooth'
        });

    };

    const handleSubmit = (val: any) => {
        let { name, email, company, _message } = val || {};

        if (name == null || name.trim() === "") {
            message.warn('Please enter your name.');
            return;
        }
        if (email == null || email.trim() === "" || !isEmail(email)) {
            message.warn('Please enter the correct email format.');
            return;
        }
        if (company == null || company.trim() === "") {
            message.warn('Please enter your company.');
            return;
        }
        if (_message == null || _message.trim() === "") {
            message.warn('Please enter your message.');
            return;
        }
        val.site = 'esiid';
        contactUs(val).then((res) => {
            console.log(res);
            setSubmitStatus(true);
        })
     
    }

    return <div className="contactPage">
        <div className="title">
            <label className="contact_us">Contact Us</label>
            <label className="intro">Our team is happy to answer your questions.Fill out the form and we'll be in touch as soon as possible</label>
        </div>
        <ProForm
            onFinish={async (values) => {
                handleSubmit(values);
            }}

            submitter={{
                searchConfig: { submitText: 'Submit' },
                resetButtonProps: { style: { display: 'none' } },
                submitButtonProps: submitStatus ? { style: { display: 'none' } } : {},
            }}
        >
            {!submitStatus && <ProForm.Group>
                <ProFormText name="name" label="Your Name" placeholder="" />
                <ProFormText name="email" label="Email" placeholder="" />
            </ProForm.Group>}
            {!submitStatus && <ProFormText name="company" label="Company" placeholder="" />}
            {!submitStatus && <ProFormTextArea name="_message" label="Message" placeholder="" />}

            {submitStatus &&
                <Alert
                    message="Submit Successfully."
                    description="We will contact you ASAP."
                    type="success"
                    showIcon
                    style={{ backgroundColor: 'unset', border: 'none' }}
                />}
        </ProForm>
    </div>
}