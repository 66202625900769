import { NavLink, Outlet } from "react-router-dom";
import { Footer } from "../../component/Footer";
import "../../styles/homeWrap.scss";
export const HomeWrap = () => {

    return <div className="homeWrap">
        <div className='top-menu'>
            <div className='top-menu-left'>
                <NavLink to='/home'>
                    {/* <p className='home'>lookup</p> */}
                    <img src={require("../image/logo/esiid_logo.png")} alt="" />
                </NavLink>
            </div>
            <div className='top-menu-right'>
                <NavLink to='/about_us'><p className='about_us'>ABOUT US</p></NavLink>
                <NavLink to='/contact'><p className='contact'>CONTACT US</p></NavLink>
            </div>
        </div>
        <div>
            <Outlet />
        </div>
        <Footer />
    </div >;
}